import React, { useState } from "react";
import "./FAQ.css";

const FAQ = () => {
  const [faqOpen, setFaqOpen] = useState(Array(8).fill(false));

  const toggleFaq = (index) => {
    setFaqOpen((prev) => {
      const newOpen = [...prev];
      newOpen[index] = !newOpen[index];
      return newOpen;
    });
  };

  const faqs = [
    {
      question: "What is an NFT?",
      answer:
        "NFT stands for Non-Fungible Token, a unique digital asset verified using blockchain technology. Unlike cryptocurrencies, NFTs cannot be exchanged one-to-one.",
    },
    {
      question: "How can I purchase an NFT?",
      answer:
        "You can purchase NFTs on Magiceden ... . You'll need a compatible cryptocurrency wallet and some USDC.",
    },
    {
      question: "Why do you sell the NFT collection?",
      answer:
        "We want to raise funds to our platform as a service Coders Aid Fund and NFTs are an engaging and fun way to do it. Moreover, we get a community of future potential donors.",
    },
    // {
    //   question: "What is the reasoning of the price per NFT?",
    //   answer:
    //     "We need to charge about $60 per NFT to run the 8 month project and pay taxes, we want to donate $15 per NFT to different charities and we pay about 15% to affiliates, the platform fee is 2%......   .",
    // },
    // {
    //   question: "What is whitelisting?",
    //   answer:
    //     "Whitelisting is the process of securing a spot to mint an NFT before the public sale. It often requires completing tasks or being an early supporter of the project.",
    // },
    {
      question: "What is minting?",
      answer:
        "Minting is the process of creating a new NFT and registering it on the blockchain. Once minted, the NFT is transferred to your wallet and becomes part of the blockchain ledger.",
    },
    {
      question: "Why do I need a digital wallet?",
      answer:
        "A digital wallet is required to store your NFTs and cryptocurrencies. Popular wallets include Phantom and Solflare.",
    },
    {
      question: "Are NFTs a good investment?",
      answer:
        "NFTs can be a good investment, but they are also speculative and volatile. It's important to do your own research and only invest what you can afford to lose.",
    },
    {
      question: "What are gas fees?",
      answer:
        "Gas fees are the transaction fees required to execute a transaction on the Solana blockchain. They vary based on network congestion.",
    },
    {
      question: "How can I resell my NFT?",
      answer:
        "You can resell your NFT on various NFT marketplaces by listing it for a price of your choosing. The resale process will involve paying gas fees.",
    },
  ];

  return (
    <div className="faq-page">
      <h1>Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${faqOpen[index] ? "active" : ""}`}
        >
          <h3 onClick={() => toggleFaq(index)}>{faq.question}</h3>
          {faqOpen[index] && <p>{faq.answer}</p>}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
