import React from "react";
import NFTbackground5 from "../images/NFTbackground5.jpg";
// import Partner1Image from "../images/Partner1.jpg";
// import Partner2Image from "../images/Partner2.jpg";
import Partner3Image from "../images/Partner3.png";
import Partner4Image from "../images/Partner4.jpeg";
import Partner5Image from "../images/Partner5.png";
import "./Partners.css";

const partners = [
  // {
  //   name: "SuperteamUK",
  //   description:
  //     "Superteam is the Talent Layer of Solana. You can join a community of the best talent learning, earning and building in crypto.",
  //   image: Partner1Image,
  //   website: "https://superteam.fun/",
  // },
  // {
  //   name: "Solana Foundation",
  //   description:
  //     "The Solana Foundation is a non-profit foundation based in Zug, Switzerland, dedicated to the decentralization, adoption, and security of the Solana ecosystem.",
  //   image: Partner2Image,
  //   website: "https://solana.org/",
  // },
  {
    name: "Code Your Future",
    description:
      "CodeYourFuture gives its support, a UK-based non-profit organisation that trains some of the most deprived members of society to gain the tech skills they need to succeed. After the sale of our first-generation NFT collection Bestest Besties, we will donate $10,000 to this charity.",
    image: Partner3Image,
    website: "https://codeyourfuture.io/",
  },
  {
    name: "Next Tech Girls",
    description:
      "Next Tech Girls gives its support. They smash stereotypes to inspire today's girls to become tomorrow's women in tech. After the sale of our first-generation NFT collection Bestest Besties, we will donate $10,000 to this charity.",
    image: Partner4Image,
    website: "https://www.nexttechgirls.com/",
  },
  {
    name: "ILAPI",
    description:
      "ILAPI is a non-profit, non-partisan, public policy, and educational research organization operating exclusively for education, social development, business consultancy, and decentralization in Ghana. After the sale of our first-generation NFT collection Bestest Besties, we will donate $10,000 to this charity.",
    image: Partner5Image,
    website: "https://ilapi.org/",
  },
];

const Partners = () => {
  return (
    <div
      className="partners-container"
      style={{ backgroundImage: `url(${NFTbackground5})` }}
    >
      <h1>Supporters</h1>
      <h2>Coding bootcamps</h2>
      <div className="partners-content">
        {partners.map((partner, index) => (
          <a
            key={index}
            href={partner.website}
            target="_blank"
            rel="noopener noreferrer"
            className="partner-link"
          >
            <div className="partner">
              <img
                src={partner.image}
                alt={partner.name}
                className="partner-image"
              />
              <div className="partner-text">
                <h2>{partner.name}</h2>
                <p>{partner.description}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Partners;
